import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import SearchRangeRow from './searchrangerow';
import { setCustomFilters, } from "../actions/index";
import { Row, Col, } from "react-bootstrap";

const RunStatus = (props) => {
    const { } = props;
   
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const updateState = (col, data) => {
        let filters = state.custom_filters;
        filters[col] = { ...filters[col], ...data };
        dispatch(setCustomFilters(filters));
    }

    return (
        <Row className="text-center">
            <Col lg={12}>
                <table>
                    <tbody>                       

                        <SearchRangeRow
                            title="Sequencing Progress" col="runstatus"
                            op={state.custom_filters.runstatus != undefined ? state.custom_filters.runstatus.op : ''}
                            min={state.custom_filters.runstatus.min} max={state.custom_filters.runstatus.max}
                            low={state.custom_filters.runstatus.low} high={state.custom_filters.runstatus.high}
                            updateState={updateState} dirty={state.custom_filters.runstatus.dirty} saved={state.custom_filters.runstatus.saved}
                            disableLow={state.custom_filters.runstatus.disableLow} disableHigh={state.custom_filters.runstatus.disableHigh}
                            disableSlider={state.custom_filters.runstatus.disableSlider} />

                        <SearchRangeRow
                            title="Elapsed Time" col="runtime"
                            op={state.custom_filters.runtime != undefined ? state.custom_filters.runtime.op : ''}
                            min={state.custom_filters.runtime.min} max={state.custom_filters.runtime.max}
                            low={state.custom_filters.runtime.low} high={state.custom_filters.runtime.high}
                            updateState={updateState} dirty={state.custom_filters.runtime.dirty} saved={state.custom_filters.runtime.saved}
                            disableLow={state.custom_filters.runtime.disableLow} disableHigh={state.custom_filters.runtime.disableHigh}
                            disableSlider={state.custom_filters.runtime.disableSlider} />                       

                        <SearchRangeRow
                            title="Num Flows" col="numflows"
                            op={state.custom_filters.numflows != undefined ? state.custom_filters.numflows.op : ''}
                            min={state.custom_filters.numflows.min} max={state.custom_filters.numflows.max}
                            low={state.custom_filters.numflows.low} high={state.custom_filters.numflows.high}
                            updateState={updateState} dirty={state.custom_filters.numflows.dirty} saved={state.custom_filters.numflows.saved}
                            disableLow={state.custom_filters.numflows.disableLow} disableHigh={state.custom_filters.numflows.disableHigh}
                            disableSlider={state.custom_filters.numflows.disableSlider} />

                        <SearchRangeRow
                            title="Beads (M)" col="numbeads"
                            op={state.custom_filters.numbeads != undefined ? state.custom_filters.numbeads.op : ''}
                            min={state.custom_filters.numbeads.min} max={state.custom_filters.numbeads.max}
                            low={state.custom_filters.numbeads.low} high={state.custom_filters.numbeads.high}
                            updateState={updateState} dirty={state.custom_filters.numbeads.dirty} saved={state.custom_filters.numbeads.saved}
                            disableLow={state.custom_filters.numbeads.disableLow} disableHigh={state.custom_filters.numbeads.disableHigh}
                            disableSlider={state.custom_filters.numbeads.disableSlider} />

                        <SearchRangeRow
                            title="Pass Filter Rate (%)" col="pass_filter_rate"
                            op={state.custom_filters.pass_filter_rate != undefined ? state.custom_filters.pass_filter_rate.op : ''}
                            min={state.custom_filters.pass_filter_rate.min} max={state.custom_filters.pass_filter_rate.max}
                            low={state.custom_filters.pass_filter_rate.low} high={state.custom_filters.pass_filter_rate.high}
                            updateState={updateState} dirty={state.custom_filters.pass_filter_rate.dirty} saved={state.custom_filters.pass_filter_rate.saved}
                            disableLow={state.custom_filters.pass_filter_rate.disableLow} disableHigh={state.custom_filters.pass_filter_rate.disableHigh}
                            disableSlider={state.custom_filters.pass_filter_rate.disableSlider}/>
                            
                        <SearchRangeRow
                            title="Reads PF (M)" col="num_reads_pass_filter"
                            op={state.custom_filters.num_reads_pass_filter != undefined ? state.custom_filters.num_reads_pass_filter.op : ''}
                            min={state.custom_filters.num_reads_pass_filter.min} max={state.custom_filters.num_reads_pass_filter.max}
                            low={state.custom_filters.num_reads_pass_filter.low} high={state.custom_filters.num_reads_pass_filter.high}
                            updateState={updateState} dirty={state.custom_filters.num_reads_pass_filter.dirty} saved={state.custom_filters.num_reads_pass_filter.saved}
                            disableLow={state.custom_filters.num_reads_pass_filter.disableLow} disableHigh={state.custom_filters.num_reads_pass_filter.disableHigh}
                            disableSlider={state.custom_filters.num_reads_pass_filter.disableSlider}/>
                        
                         <SearchRangeRow
                            title="Aligned Bases (TB)" col="aligned_bases"
                            op={state.custom_filters.aligned_bases != undefined ? state.custom_filters.aligned_bases.op : ''}
                            min={state.custom_filters.aligned_bases.min} max={state.custom_filters.aligned_bases.max}
                            low={state.custom_filters.aligned_bases.low} high={state.custom_filters.aligned_bases.high}
                            updateState={updateState} dirty={state.custom_filters.aligned_bases.dirty} saved={state.custom_filters.aligned_bases.saved}
                            disableLow={state.custom_filters.aligned_bases.disableLow} disableHigh={state.custom_filters.aligned_bases.disableHigh}
                            disableSlider={state.custom_filters.aligned_bases.disableSlider} />

                        <SearchRangeRow
                            title="Output Reads (M)" col="pf_output_reads"
                            op={state.custom_filters.pf_output_reads != undefined ? state.custom_filters.pf_output_reads.op : ''}
                            min={state.custom_filters.pf_output_reads.min} max={state.custom_filters.pf_output_reads.max}
                            low={state.custom_filters.pf_output_reads.low} high={state.custom_filters.pf_output_reads.high}
                            updateState={updateState} dirty={state.custom_filters.pf_output_reads.dirty} saved={state.custom_filters.pf_output_reads.saved}
                            disableLow={state.custom_filters.pf_output_reads.disableLow} disableHigh={state.custom_filters.pf_output_reads.disableHigh} 
                            disableSlider={state.custom_filters.pf_output_reads.disableSlider} />

                        <SearchRangeRow
                            title="Feasible Throughput (TB)" col="feasible_throughput"
                            op={state.custom_filters.feasible_throughput != undefined ? state.custom_filters.feasible_throughput.op : ''}
                            min={state.custom_filters.feasible_throughput.min} max={state.custom_filters.feasible_throughput.max}
                            low={state.custom_filters.feasible_throughput.low} high={state.custom_filters.feasible_throughput.high}
                            updateState={updateState} dirty={state.custom_filters.feasible_throughput.dirty} saved={state.custom_filters.feasible_throughput.saved}
                            disableLow={state.custom_filters.feasible_throughput.disableLow} disableHigh={state.custom_filters.feasible_throughput.disableHigh} 
                            disableSlider={state.custom_filters.feasible_throughput.disableSlider} />
                            
                    </tbody>
                </table>
            </Col>
        </Row>
    );
}

export default RunStatus;